// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Tooltip.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Tooltip.tsx");
  import.meta.hot.lastModified = "1726176605631.3848";
}
// REMIX HMR END

import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import * as React from 'react';
import { useState } from 'react';
import { cn } from '~/utils/cn';
import { hasHorizontalOverflow, hasVerticalOverflow } from '~/utils/general';
const TooltipContent = React.forwardRef(_c = ({
  className,
  sideOffset = 4,
  ...props
}, ref) => <TooltipPrimitive.Content ref={ref} sideOffset={sideOffset} className={cn('z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1', className)} {...props} />);
_c2 = TooltipContent;
TooltipContent.displayName = TooltipPrimitive.Content.displayName;
function TooltipMain({
  children,
  onlyShowIfOverflow = {
    vertical: false,
    horizontal: false
  },
  triggerRef,
  ...props
}) {
  _s();
  const [isOpen, setIsOpen] = useState(false);
  return <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root {...props} open={isOpen} onOpenChange={e => {
      const {
        horizontal,
        vertical
      } = onlyShowIfOverflow;
      const shouldShow = horizontal && hasHorizontalOverflow(triggerRef?.current) || vertical && hasVerticalOverflow(triggerRef?.current) || !horizontal && !vertical;
      if (!shouldShow) {
        setIsOpen(false);
        return;
      }
      setIsOpen(e);
    }}>
        {children}
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>;
}
_s(TooltipMain, "+sus0Lb0ewKHdwiUhiTAJFoFyQ0=");
_c3 = TooltipMain;
export const Tooltip = Object.assign(TooltipMain, {
  Trigger: TooltipPrimitive.Trigger,
  Content: TooltipContent,
  Provider: TooltipPrimitive.Provider,
  Portal: TooltipPrimitive.Portal
});
_c4 = Tooltip;
var _c, _c2, _c3, _c4;
$RefreshReg$(_c, "TooltipContent$React.forwardRef");
$RefreshReg$(_c2, "TooltipContent");
$RefreshReg$(_c3, "TooltipMain");
$RefreshReg$(_c4, "Tooltip");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;